"use client";

import Core from "@/components/core";
import Fragments from "@/components/fragments";
import { useState, useEffect } from "react";
import { useFetchGalleries } from "@/hooks/fetchDataGalleries";
import LoadingSkeleton from "@/app/loading";
import Image from "next/image";

const formatDate = (dateString: string): string => {
  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

const GalleryLayouts = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const galleries = useFetchGalleries();
  const itemsPerPage = 6;

  const totalItems = galleries?.length || 0;
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = galleries?.slice(startIndex, endIndex);

  const handleNextPage = () => {
    if (currentPage < Math.ceil(totalItems / itemsPerPage) - 1) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="bg-white mt-[10px] p-[10px] block lg:grid grid-cols-1 lg:grid-cols-3 gap-5">
      {isLoading ? (
        <div className="col-span-3">
          <LoadingSkeleton />
        </div>
      ) : galleries === undefined ? (
        <div className="text-center col-span-3 py-8">
          <Image
            src="/images/data-not-found.webp"
            alt="No data available"
            width={300}
            height={300}
            className="mx-auto"
          />
          <p className="text-slate-500 mt-4">No data available</p>
        </div>
      ) : (
        <>
          {currentItems?.map((item) => (
            <Fragments.GalleryCard
              key={item.id}
              image={`https://admin.macspace.id/public/${item.image}`}
              name={item.name}
              updated_at={formatDate(item.updated_at)}
            />
          ))}
          <div className="col-span-3">
            <Core.Pagination
              currentPage={currentPage}
              totalItems={totalItems}
              itemsPerPage={itemsPerPage}
              onNextPage={handleNextPage}
              onPreviousPage={handlePrevPage}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default GalleryLayouts;
