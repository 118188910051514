import Timelines from "./Timelines";
import Layanan from "./Layanan";
import ConntrollerInput from "./ControllerInput";
import GalleryCard from "./GalleryCard";
import EmptyState from "./EmptyState";

const Fragments = {
  Timelines,
  Layanan,
  Controller: ConntrollerInput,
  GalleryCard,
  EmptyState,
};

export default Fragments;
