import Link from "next/link";
import Image from "next/image";
import { BiChevronRight } from "react-icons/bi";
import Routes from "@/data/Routes/routes.json";
import { TbBrandGoogleMaps } from "react-icons/tb";

const Footer = () => {
  const devicesSeo = [
    { name: "Macbook" },
    { name: "iMac" },
    { name: "Mac Mini" },
    { name: "Mac Pro" },
  ];

  return (
    <div>
      <footer className="footer bg-[#1d1d1e] relative text-gray-200 dark:text-gray-200">
        <div className="container relative px-5 md:px-24">
          <div className="grid grid-cols-12">
            <div className="col-span-12">
              <div className="py-[60px] px-0">
                <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                  <div className="lg:col-span-10 md:col-span-12">
                    <Link
                      className="text-[22px] focus:outline-none"
                      href="/"
                      target="_blank"
                    >
                      <Image
                        src="/images/logo/LOGO MACSPACE - WHITE.webp"
                        alt="logo"
                        width={100}
                        height={100}
                        className="lg:w-[30%] w-[50%]"
                      ></Image>
                    </Link>
                    <div className="flex items-center justify-center">
                      <p className="mt-6 text-gray-300">
                        MacSpace adalah tempat layanan perbaikan dan penggantian
                        part yang sudah terverifikasi dan memiliki reputasi yang
                        baik. Fokus dengan perbaikan perangkat Mac, kami
                        memiliki misi untuk bisa menjadi tempat service yang
                        terintegritas dan berkualitas.
                      </p>
                    </div>

                    <div className="text-start mt-6 text-gray-300 text-xs">
                      {devicesSeo.map((device) => (
                        <span key={device.name}>Service {device.name} </span>
                      ))}

                      {devicesSeo.map((device) => (
                        <span key={device.name}>
                          Service {device.name} Jakarta, Service {device.name}{" "}
                          Depok, Service {device.name} Tangerang, Service{" "}
                          {device.name} Bekasi{" "}
                        </span>
                      ))}

                      {devicesSeo.map((device) => (
                        <span key={device.name}>
                          Service {device.name} Terbaik, Service {device.name}{" "}
                          Terdekat, Service {device.name} Berkualitas{" "}
                        </span>
                      ))}
                    </div>

                    <p className="mt-6 text-gray-300">
                      GRAND ITC Permata Hijau L.3 Blok D3 No.4, Jakarta Selatan,
                      DKI Jakarta 12210
                    </p>

                    <p className="mt-6 text-gray-300">
                      Mangga Dua Mall Lantai Dasar No 21A, Kecamatan Sawah
                      Besar, Daerah Khusus Ibukota Jakarta 10730, Kota Jakarta
                      Pusat, DKI Jakarta 10730
                    </p>
                  </div>
                  <div className="lg:col-span-2 md:col-span-4">
                    <h5 className="tracking-[1px] text-gray-100 font-semibold">
                      Layanan
                    </h5>
                    <ul className="list-none footer-list mt-6">
                      {Routes.map((item, index) => (
                        <li key={index} className="inline my-[30px]">
                          <Link
                            href={item.link}
                            target={"_blank"}
                            className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out flex items-center"
                          >
                            <BiChevronRight />
                            {item.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
